//@import "../stylehub.ptetrain.com/scss/helpers/variables";

//@debug $spacer;

* {
  font-family: "Public Sans", sans-serif;
}

html,
body,
header,
section,
footer,
main,
article,
div,
ul,
ol,
li,
img,
a,
span,
em,
del,
legend,
center,
strong,
var,
fieldset,
form,
label,
dl,
dt,
dd,
cite,
input,
hr,
time,
mark,
code,
figcaption,
figure,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  -webkit-touch-callout: none;
  text-size-adjust: none;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  font-size: 16px;
  color: black;
}

ol,
ul {
  list-style: none;
}

input,
button,
textarea {
  border: 0;
  margin: 0;
  padding: 0;
  background-color: rgb(0 0 0 / 0%);
  outline: 0;
}

a,
a:link,
a:visited,
a:hover,
a:active,
a:focus {
  outline: none !important;
  text-decoration: none !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.common-width {
  width: 1200px;
  margin: auto;
}

@media only screen and (max-width: 960px) {
  .common-width {
    width: auto;
    margin: auto 15px;
  }
}

:root {
  --primary-color: #595bb2;
  --primary-disable-color: #babbe1;
  --primary-shadow-color: #48bfe3;
  --shallow-color: rgb(235 235 246);
  --shallow-bg-color: #f7f9ff;
  --secondary-color: #f77502;
  --fg-color: #1f1c24;
  --margin: 60px;
  --border-radius: 0.5vw;
  --box-shadow: 0 0 10px lightgrey;
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;
  --fw-extrabold: 800;
  --fw-black: 900;
  --clr-gray100: #f9fbff;
  --clr-gray150: #f4f6fb;
  --clr-gray200: #eef1f6;
  --clr-gray300: #e1e5ee;
  --clr-gray400: #767b91;
  --clr-gray500: #4f546c;
}

::selection {
  background: var(--primary-color);
  color: #fff;
}

.shadow {
  box-shadow: 0 8px 8px 0 rgb(89 91 178 / 4%);
  border-radius: 8px;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 12px 12px 0 rgb(89 91 178 / 10%);
    transform: translateY(-5px);
  }
}

.modal-backdrop {
  opacity: 0.8 !important;
}

.practice-panel {
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

/* Practice questions */
.mn-practice {
  width: 100%;
  position: relative;

  .practice-panel {
    padding-top: 5vh;
    padding-bottom: 10vh;
  }
}
