#mn-admin-home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  overflow-y: scroll;

  .admin-banner {
    width: 100%;
    height: 400px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .swiper-container {
      border-radius: var(--border-radius);
      background-color: white;
      box-shadow: var(--box-shadow);
    }

    .my-swiper-top {
      height: 80%;
      width: 100%;
      background-color: orange;
    }

    .my-swiper-bottom {
      height: 20%;
      background-color: black;
      box-sizing: border-box;
      padding: 10px 0;

      .swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;
      }

      .swiper-slide-thumb-active {
        opacity: 1;
      }
    }
  }

  .home-container {
    display: grid;
    grid-template: repeat(4, 1fr);
    gap: 1vw;
    grid-template-areas:
      "statistics-item statistics-item mock-item shadow-item"
      "statistics-item statistics-item mock-item test-item"
      "memories-item memories-item high-item high-item"
      "memories-item memories-item tool-item tool-item"
      "bottom-item bottom-item bottom-item bottom-item";

    & > div {
      background-color: white;
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
    }

    .bottom-item {
      grid-area: bottom-item;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1vw;

      div {
        border-radius: var(--border-radius);
      }

      div:nth-child(1) {
        background-color: red;
      }

      div:nth-child(2) {
        background-color: orange;
      }

      div:nth-child(3) {
        background-color: blue;
      }
    }

    .card {
      font-size: 15px;
      overflow: hidden;

      a {
        display: grid;
        grid-template-rows: 0.5fr 1.5fr 0.5fr;
        width: 100%;
        height: 100%;

        .header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background-color: rgb(53 220 216);
          color: rgb(255 255 255);

          p {
            border: 1px solid rgb(255 255 255);
            padding: 3px 7px;
            border-radius: 4px;
          }
        }

        .body {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: stretch;
          height: auto;

          div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .title {
            color: rgb(51 51 51);
            font-size: 30px;
          }

          .des {
            color: gray;
            font-size: 20px;
          }
        }

        .footer {
          color: rgb(153 153 153);
          display: flex;
          align-items: center;
          justify-content: flex-end;

          svg {
            width: 1.5vw;
            height: 1.5vw;
          }
        }
      }
    }

    .statistics-item { grid-area: statistics-item; }
    .memories-item { grid-area: memories-item; }
    .mock-item { grid-area: mock-item; }
    .shadow-item { grid-area: shadow-item; }
    .test-item { grid-area: test-item; }
    .high-item { grid-area: high-item; }
    .tool-item { grid-area: tool-item; }
  }
}

@media only screen and (max-width: 960px) {
  #mn-admin-home {
    .home-container {
      display: grid;
      grid-template: repeat(8, 1fr);
      grid-template-areas:
        "statistics-item"
        "memories-item"
        "mock-item"
        "shadow-item"
        "test-item"
        "high-item"
        "tool-item"
        "bottom-item";
    }
  }
}
