#mn-vocabulary {
  width: 100%;
  box-shadow: var(--box-shadow);
  overflow-y: scroll;

  .vocabulary-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: center;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;
    }

    .books {
      // background-color: var(--shallow-bg-color);
      border-radius: var(--border-radius);
      background-color: white;

      .books-list {
        background-color: white;
        border-radius: var(--border-radius);
        border: 2px solid lightgray;
        display: flex;
        flex-direction: row;
        height: 140px;
        align-items: center;
        padding: 10px;
        gap: 1rem;

        img {
          width: 80px;
          height: 100px;
          border-radius: var(--border-radius);
        }

        .list-tab {
          grid-template-columns: 1fr 1fr;
          gap: 1rem;
          width: 100%;
        }

        #button {
          background-color: #9049bf;
          width: 80px;
          height: 30px;
          border: none;
          padding: 2px;
          text-align: center;
        }
      }
    }
  }
}
