.clickable-paragraph {
  span.clickable-word {
    cursor: pointer;
    font-size: 18px;
    display: inline-block;

    &:hover {
      background-color: rgb(233 233 233);
    }
  }
}
