.btn-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-advisory-btn {
  display: inline-block;
  vertical-align: middle;
  width: 240px;
  margin: auto 20px;
  margin-bottom: 10px;
  max-width: 300px;
  border-radius: 30px;
  font-size: 22px;
  line-height: 48px;
  background: linear-gradient(0deg, #595bb2 0%, #6c6ec9 100%), linear-gradient(#595bb2, #595bb2);
  color: #fff;
  box-shadow: 0 4px 4px 0 rgb(89 91 178 / 30%);
}

@media only screen and (max-width: 960px) {
  .common-advisory-btn {
    font-size: 14px;
    line-height: 40px;
    width: 160px;
    margin: auto 5px;
  }
}
