#mn-stream-page {
  .body {
    width: 100%;
    background-color: white;
    padding: 15px;

    .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 50rem;

      .title {
        border-bottom: 4px solid #9921cc;
      }

      .header-right {
        float: right;

        button {
          margin-right: 10px;
          color: #9921cc;
          border-radius: 15px;
          border: 2px solid #9921cc;
          height: 30px;

          &:hover {
            background-color: #9921cc;
            color: white;
          }
        }

        .create-btn {
          width: 150px;
        }

        .my-list-btn,
        .fav-btn {
          width: 80px;
        }
      }
    }
  }
}
