#mn-modal-add {
  width: 100%;

  .body {
    text-align: left;
    padding: 30px;
    width: 100%;

    .upload-file {
      border: 3px dashed lightgray;
      color: gray;
      text-align: center;
      width: 150px;
      height: 150px;
      margin: 10px;

      p {
        font-size: 25px;
        margin-top: 50px;
      }

      &:hover {
        border: 3px dashed lightblue;
      }
    }

    .date-input {
      // border-radius: var(--border-radius);
      border: 3px solid lightgray;
      color: gray;
      width: 250px;
      height: 50px;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .score-list {
      .score-info {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        text-align: center;

        .input-score {
          input {
            margin-left: 40px;
          }

          margin-top: 10px;
          margin-right: 10px;
          border-radius: var(--border-radius);
          background-color: lightgray;
          text-align: center;
          width: 80px;
          height: 80px;
        }
      }
    }

    .grammar-list {
      .grammar-info {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        text-align: center;

        .input-score {
          input {
            margin-left: 30px;
          }

          margin-top: 10px;
          margin-right: 10px;
          border-radius: var(--border-radius);
          background-color: lightgray;
          text-align: center;
          width: 60px;
          height: 60px;
          word-wrap: break-word;
          font-size: 14px;
        }
      }
    }
  }
}
