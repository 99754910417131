#mn-course-banner {
  background-color: #fe6b69;
  position: relative;
  height: calc(100vh - 80px);
  color: white;
  font-weight: bold;

  .content {
    display: flex;
    justify-content: space-between;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 120px;

    .left {
      position: relative;

      img {
        position: absolute;
        bottom: 0;
        left: 22vw;
        width: 15vw;
        transform: translateY(80px);
      }

      h1 {
        font-size: 4.3vw;
        line-height: 1.5;
      }

      h5 {
        font-size: 2.5vw;
        margin-top: 2vw;
        margin-bottom: 2vw;
        white-space: wrap;
      }

      .btns {
        width: 13vw;

        div {
          width: 100%;
          text-align: center;
          margin-bottom: 1rem;
          box-shadow: inset 0 1px 0 0 #f7c5c0;
          background: linear-gradient(to bottom, #fc8d83 5%, #e4685d 100%);
          background-color: #fc8d83;
          border-radius: 1vw;
          border: 1px solid #d83526;
          font-size: 1.5vw;
          font-weight: bold;
          padding: 0.5vw 1.2vw;
          text-shadow: 0 1px 0 #b23e35;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    form {
      border: 10px solid pink;
      border-image: repeating-linear-gradient(45deg, pink, pink 1%, purple 1%, purple 8%) 10;
      color: white;

      &:hover {
        border-image: repeating-linear-gradient(45deg, purple, purple 1%, pink 1%, pink 8%) 10;
      }
    }
  }

  .curve-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);

    svg {
      position: relative;
      display: block;
      width: calc(135% + 1.3px);
      height: 67px;
      transform: rotateY(180deg);
    }

    .shape-fill {
      fill: white;
    }
  }
}

@media only screen and (max-width: 768px) {
  #mn-course-banner {
    background-color: #fe6b69;
    position: relative;
    height: 100vh;
    color: white;

    .content {
      display: flex;
      justify-content: space-between;
      padding-left: 2vw;
      padding-right: 2vw;
      padding-top: 10px;

      .left {
        h1 {
          font-size: 2rem;
        }

        h5 {
          font-size: 1rem;
        }

        .btns {
          width: 8rem;

          div {
            font-size: 1rem;
          }
        }

        img {
          transform: translateY(0);
        }
      }
    }
  }
}
