#mn-practice-swt {
  .slideshow-container {
    position: relative;
  }

  .my-slides {
    display: none;
  }

  .active-slides {
    display: block;
    padding: 10px;
  }

  p {
    line-height: 1.7;
  }
}
