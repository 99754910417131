#mn-book-page {
  width: 100%;
  box-shadow: var(--box-shadow);
  background-color: white;
  overflow-y: scroll;

  .book-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: left;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;

      .go-back {
        color: white;
      }
    }

    .books {
      border-radius: var(--border-radius);
      background-color: white;

      .books-list {
        background-color: white;
        border-radius: var(--border-radius);
        border: 2px solid lightgray;
        display: flex;
        flex-direction: row;
        height: 140px;
        align-items: center;
        padding: 10px;
        gap: 1rem;

        img {
          width: 100px;
          height: 120px;
          border-radius: var(--border-radius);
        }

        .list-tab {
          grid-template-columns: 1fr 1fr;
          gap: 1rem;
          width: 100%;
        }
      }

      .words-list {
        border: none;

        .unactive {
          color: lightgray;
          display: inline;
          margin: 10px;
        }

        .active {
          color: black;
          border-bottom: 2px solid blue;
          align-content: stretch;
        }
      }

      .newword-list,
      .masterword-list {
        background-color: var(--shallow-bg-color);
        border-radius: var(--border-radius);
        width: 100%;

        p {
          margin: 10px;
        }
      }
    }
  }
}
