#mn-schedule {
  width: 100%;
  box-shadow: var(--box-shadow);
  background-color: white;
  overflow-y: scroll;

  .schedule-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: left;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;

      .go-back {
        color: white;
      }
    }

    .body {
      .tutor-space {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 10px solid lightgray;

        .tutor-img {
          border-radius: 15px;
          margin-left: 15px;
          margin-top: 15px;

          img {
            width: 80px;
            height: 80px;
          }
        }

        .tutor-info {
          margin-left: 20px;
          margin-top: 15px;
          display: flex;
          flex-direction: column;
        }
      }

      .schedule-space {
        width: 100%;

        .schedule-table {
          .legend-table {
            background-color: white;
            display: flex;
            flex-direction: row;
            margin-top: 20px;

            .legend {
              display: flex;
              flex-direction: row;
              margin-bottom: 20px;

              .color-label {
                padding: 10px;
                border: 2px solid lightgray;
                margin-right: 15px;
                margin-left: 15px;
                display: inline-block;
                width: 10px;
                height: 10px;
              }
            }

            .book-btn {
              border-radius: 20px;
              color: rgb(185 78 212);
              border: 2px solid rgb(185 78 212);
              width: 100px;
              height: 30px;
              margin-left: 800px;

              &:hover {
                background-color: rgb(185 78 212);
                color: white;
              }
            }
          }

          table {
            margin-top: 20px;
            margin-left: 50px;
            width: 80%;

            td,
            th {
              border: 2px solid lightgray;
              text-align: center;
              word-wrap: break-word;
              padding: 10px;
            }

            .booked {
              background-color: rgb(201 46 60);
            }

            .vacant {
              background-color: rgb(104 209 92);
            }
          }
        }
      }
    }
  }
}
