#mn-modal-score {
  .body {
    .group {
      display: flex;
      flex-direction: column;
      float: left;
    }

    .container {
      width: 100%;
      background-color: #ddd;
      padding-left: 0;

      .skills {
        color: white;
        padding-left: 10px;
      }

      .oral-score {
        width: 45%;
        background-color: #2196f3;
        margin-left: 0;
      }

      .pronunciation-score {
        width: 50%;
        background-color: #2196f3;
      }

      .overall-score {
        width: 55%;
        background-color: #f44336;
      }
    }

    .analysis-rate {
      display: flex;
      flex-direction: row;
      gap: 5rem;

      .rate {
        padding-left: 20px;
        margin: 10px;
      }

      .dot-good,
      .dot-bad,
      .dot-average {
        height: 20px;
        width: 5px;
        border-radius: 50%;
        font-size: small;
        display: inline-block;
      }

      .dot-good {
        background-color: #2bcf47;
      }

      .dot-bad {
        background-color: red;
      }

      .dot-average {
        background-color: yellow;
      }
    }

    .textarea {
      border: 2px solid black;
      width: 100%;
    }
  }
}
