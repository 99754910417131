#mn-community-page {
  width: 100%;

  .bg {
    img {
      width: 100%;
      height: 552px;
    }
  }

  .body {
    background-color: white;
    padding-top: 20px;
    margin: 20px;

    .community-list {
      h4 {
        border-bottom: 5px solid #9921cc;
        width: fit-content;
        padding-bottom: 10px;
      }

      .community-item {
        display: flex;
        flex-direction: column;
        margin-left: 100px;

        .community-post {
          width: 80%;
          box-shadow: var(--box-shadow);
          margin: 15px;
          border-radius: var(--border-radius);

          .post-header {
            display: flex;
            flex-direction: row;
            margin: 10px;

            img {
              width: 80px;
              height: 80px;
              border-radius: 10px;
            }

            button {
              float: right;
              color: #9921cc;
              border-radius: 15px;
              border: 2px solid #9921cc;
              width: 150px;
              height: 30px;
              margin-left: 550px;

              &:hover {
                background-color: #9921cc;
                color: white;
              }
            }
          }

          .post-body {
            margin-left: 15px;
            display: flex;
            flex-direction: column;

            p {
              font-size: 20px;
            }

            .member-icon {
              display: flex;
              flex-direction: row;
              padding-bottom: 10px;

              img {
                margin-right: 5px;
                width: 20px;
                height: 20px;
              }
            }
          }

          &:hover {
            border: 2px solid #9921cc;
          }
        }
      }
    }
  }
}
