#mn-daily-plan {
  width: 100%;
  box-shadow: var(--box-shadow);
  overflow-y: scroll;
  background-color: var(--shallow-bg-color);

  .daily-plan-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: center;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;
    }

    .no-plans {
      label {
        margin-right: 10px;
      }

      input {
        background-color: lightgrey;
        border: 2px solid black;
        color: gray;
        width: 150px;
      }

      .no-plan-space {
        text-align: center;
        height: auto;

        img {
          width: 350px;
          height: 350px;
        }

        p {
          font-size: 20px;
          padding: 10px;
        }

        button {
          background-color: #ae24ff;
          color: white;
          font-size: 20px;
          text-align: center;
          border-radius: 30px;
          width: 150px;
          height: 50px;

          &:hover {
            background-color: #8f35c4;
          }
        }
      }
    }
  }
}
