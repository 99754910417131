#mn-common-questions {
  width: 100%;
  box-shadow: var(--box-shadow);

  .common-questions-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: center;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;
    }

    h5 {
      border-bottom: 4px solid #a366cc;
      padding-bottom: 10px;
      width: fit-content;
    }

    .body {
      .qna-list {
        .questions-list {
          border: 2px solid lightgray;
          width: 100%;
          padding: 10px;

          &:hover {
            background-color: #e1c4f5;
            border: 2px solid #a366cc;
          }

          .hide-ans {
            display: none;
          }

          .show-ans {
            float: right;
          }

          .ans-list {
            margin-left: 50px;
            color: gray;
            padding: 10px;
          }
        }
      }
    }
  }
}
