#mn-setting {
  width: 100%;
  background-color: lightgrey;

  .setting-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: center;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;
    }

    .setting {
      .setting-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 5rem;
        background-color: white;
        border-radius: var(--border-radius);

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin-left: 450px;
          margin-top: 50px;
        }

        .list-tab {
          grid-template-columns: 2fr 2fr;
          gap: 1rem;
          width: 100%;
          padding: 10px;
          border-radius: var(--border-radius);
          text-align: left;
          margin-left: 250px;

          .edit-symbol {
            display: inline;
            margin-left: 100px;
          }
        }
      }
    }
  }
}
