.mn-title {
  display: flex;
  flex-direction: column;
  align-items: center;

  .headline {
    z-index: 2;
    width: 5vw;
    height: 2px;
    background: var(--primary-color);
  }
}
