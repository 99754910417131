#mn-study-groups {
  width: 100%;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow-y: scroll;

  .study-groups-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: center;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;
    }

    .header {
      float: right;
      display: flex;
      flex-direction: row;

      .add-group {
        .add-btn {
          margin-right: 20px;
          border-radius: var(--border-radius);
          color: #ba66fa;
          border: 2px solid #ba66fa;
          width: 120px;
          height: 30px;

          &:hover {
            background-color: #ba66fa;
            color: white;
          }
        }
      }

      input {
        border: 2px solid lightgray;
        border-radius: var(--border-radius);
      }

      .search-btn {
        color: white;
        border-radius: var(--border-radius);
        background-color: #ba66fa;
        height: 30px;
        width: 70px;
        margin-left: 10px;

        &:hover {
          background-color: #661db5;
        }
      }
    }

    .body {
      .group-list {
        display: flex;
        flex-direction: column;
        width: 100%;

        .group-info {
          display: flex;
          border: 2px solid lightgray;
          flex-direction: row;
          border-radius: var(--border-radius);
          margin: 15px;
          width: 100%;

          &:hover {
            box-shadow: var(--box-shadow);
          }

          .group-icon {
            margin: 10px;

            img {
              border-radius: var(--border-radius);
              width: 150px;
              height: 150px;
            }
          }

          .detail-space {
            margin: 15px;

            h5 {
              &:hover {
                color: #ba66fa;
              }
            }

            .member-icon {
              img {
                border-radius: 15px;
                width: 30px;
                height: 30px;
                border: 1px solid gray;
                margin-right: 5px;
              }
            }

            .delete-btn {
              float: right;
              border-radius: var(--border-radius);
              background-color: lightgray;
              color: white;
              margin-left: 650px;
              width: 100px;
              height: 30px;

              &:hover {
                background-color: gray;
              }
            }
          }
        }
      }
    }
  }
}
