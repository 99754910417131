#mn-experience-sharing {
  width: 100%;
  box-shadow: var(--box-shadow);
  overflow-y: scroll;

  .experience-sharing-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: center;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;
    }

    .header {
      border-bottom: 2px solid lightgray;
      display: flex;
      flex-direction: row;
      width: 100%;

      .user-space {
        display: flex;
        flex-direction: row;
        width: 100%;

        img {
          border: 2px solid gray;
          border-radius: 50px;
          width: 50px;
          height: 50px;
          margin-right: 10px;
          margin-bottom: 10px;
        }

        p {
          margin-top: 15px;
        }
      }

      .add-btn {
        float: right;
        color: #ba66fa;
        border-radius: 15px;
        width: 130px;
        height: 30px;
        border: 2px solid #ba66fa;
        margin-top: 15px;

        &:hover {
          color: white;
          background-color: #ba66fa;
        }
      }
    }

    .body {
      margin-top: 20px;

      .comment-section {
        width: 100%;
        border-radius: var(--border-radius);
        border: 2px solid #ba66fa;
        margin-bottom: 15px;
        padding-bottom: 30px;

        &:hover {
          box-shadow: var(--box-shadow);
        }

        p {
          margin-left: 10px;
        }

        .comment-space {
          display: flex;
          flex-direction: row;

          img {
            margin: 10px;
            width: 30px;
            height: 30px;
            border: 2px solid gray;
            border-radius: 30px;
          }

          p {
            margin-top: 15px;
          }
        }

        .comment-footer {
          float: right;
          margin-right: 10px;
          color: gray;
        }
      }
    }
  }
}
