.sub-item {
  width: 100%;
  height: 100%;
  display: inline-flex;
  perspective: 1000px;
  cursor: pointer;

  .card-container {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    transform-style: preserve-3d;

    .front,
    .back {
      height: 100%;
      width: 100%;
      display: block;
      background: rgb(248 246 234);
      position: absolute;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      transition: -webkit-transform ease 500ms;
      transition: transform ease 500ms;
    }

    .front {
      z-index: 2;
      transform: rotateY(0deg);
      background: transparent;

      /* front tile styles go here! */
    }

    .back {
      transform: rotateY(-180deg);
      padding: 20px;
      font-size: 20px;
      color: black;

      /* back tile styles go here! */
    }

    &.flipped {
      .front {
        transform: rotateY(180deg);
      }

      .back {
        transform: rotateY(0deg);
      }
    }

    .image-container {
      position: relative;
      width: 100%;
      height: 100%;
      background: radial-gradient(white, #afb0b5);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .headline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 15%;
        background-color: rgb(0 0 0 / 50%);
        padding-left: 1vw;
        padding-right: 1vw;

        .name {
          font-size: 1.5vw;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .sub-item {
    height: 28vw;
  }
}
