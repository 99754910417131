/* -------------- */
.fixed-nav-item:hover {
  background-color: rgb(82 122 254 / 12%);
  border-radius: 4px;
}

.fixed-nav-item:not(:first-child) {
  margin-top: 5px;
  padding-top: 5px;
}

#mn-fixed-sidebar {
  position: fixed;
  top: 15%;
  right: 0;
  z-index: 99;
  background-color: #fff;
  border-radius: 10px 0 0 10px;
  box-shadow: -2px 2px 8px 0 rgb(51 51 51 / 20%);
  padding: 15px 5px 20px;
  transition: all 0.3s;

  &.hide {
    right: -80px;
    box-shadow: none;
  }

  .backtop-icon {
    display: block;
    width: 20px;
    margin: auto;
    margin-top: 15px;
    cursor: pointer;
  }

  .expand-handle {
    width: 31px;
    height: 101px;
    position: absolute;
    left: -30px;
    top: 50%;
    z-index: 399;
    transform: translateY(-50%);
    background-image: url("../../../../public/assets/images//button_in.avif");
    background-size: 100% 100%;
    cursor: pointer;
    display: none;
  }

  svg {
    width: 40%;
    height: 40%;
  }

  .fixed-nav-item {
    display: block;
    width: 70px;
    margin: auto;
    cursor: pointer;
    padding-bottom: 10px;

    &:nth-child(3) {
      position: relative;

      img {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateX(-100%);
        width: 100px;
        border: 5px solid white;
      }

      &:hover img {
        display: block;
      }
    }
  }
}

.fixed-nav-item .item-text {
  text-align: center;
  line-height: 1;
  margin-top: 3px;
  font-size: 12px;
  color: #333;
}

.fixed-nav-item:hover .item-text {
  color: #595bb2;
}

.fixed-nav-jlq {
  position: relative;
}

.fixed-nav-jlq-info {
  width: 400px;
  position: absolute;
  left: -400px;
  top: -80px;
  display: none;
}

.fixed-nav-jlq-info-bg {
  display: block;
  width: 100%;
}

.fixed-nav-jlq-info .service-code {
  display: block;
  width: 124px;
  position: absolute;
  top: 42px;
  left: 42px;
}

.fixed-nav-jlq:hover .fixed-nav-jlq-info {
  display: block;
}

@media only screen and (max-width: 260px) {
  #mn-fixed-sidebar {
    width: 260px;

    .expand-handle {
      display: block;

      &.hide {
        background-image: url("../../../../public/assets/images/button_out.avif");
      }
    }
  }
}

/* ----------------------- */
