#mn-practice-rfib {
  .blanks {
    display: inline-block;
    text-align: center;
    width: 120px;
    height: 34px;
    border: 2px dotted black;
  }

  table {
    width: 500px;
    height: 200px;
    border: 1px solid black;
  }

  td {
    float: left;
  }

  .box2 {
    border: none;
    border-radius: var(--border-radius);
    background-color: aquamarine;
    text-align: center;
    width: fit-content;
    padding: 2px;
    height: 35px;
    margin-left: 5px;
    display: inline;
  }

  .textboxes {
    width: 110px;
    height: fit-content;
    background-color: white;
    text-align: center;
    float: left;
    margin: 5px;
    border: 1px solid black;
  }

  .slideshow-container {
    position: relative;
  }

  .my-slides {
    display: none;
  }

  .active-slides {
    display: block;
    padding: 10px;
  }

  .content,
  .answer {
    float: bottom;
    text-align: center-left;
  }

  .answer {
    width: 1150px;
    height: fit-content;
    padding: 5px;
    text-align: center;
    background-color: #999;
  }

  .check-btn {
    color: white;
    border: none;
    text-align: center;
    padding: 10px 22px;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 10px;
    background-color: #8155c2;
  }

  .check-btn:hover {
    background-color: #4e2b82;
  }

  #result {
    font-size: 20px;
  }

  #box1,
  #box2 {
    float: bottom;
    text-align: center-left;
  }

  #box2 {
    background-color: #999;
    height: 100px;
    padding-top: 10px;
    padding-left: 50px;
  }
}
