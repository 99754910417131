#mn-memory {
  width: 100%;
  box-shadow: var(--box-shadow);

  .practice-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: center;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;
    }

    .body {
      .table-head {
        display: flex;
        gap: 12rem;
        margin-left: 25px;
        margin-top: 10px;
        color: gray;
        border-bottom: 2px solid lightgray;
        width: 100%;
        padding-bottom: 10px;
        font-size: 20px;

        button {
          border-radius: var(--border-radius);
          color: white;
          background-color: #ae24ff;
          width: 140px;
          height: 40px;

          &:hover {
            background-color: #8f35c4;
          }
        }
      }

      .empty-space {
        text-align: center;
        margin-left: 150px;

        img {
          padding-bottom: 10px;
          width: 400px;
          height: 400px;
        }

        p {
          font-size: 20px;
        }
      }
    }
  }
}
