#mn-material {
  .material-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 31px;
  }

  .material-item {
    position: relative;
    width: 23%;
    height: 10vw;
    background-size: 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    padding: 2vw 0 0 1.2vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0.5vw;
  }

  .material-item:nth-child(1) {
    background: linear-gradient(to right top, rgb(105 118 206), rgb(105 118 206 / 50%));
  }

  .material-item:nth-child(2) {
    background: linear-gradient(to right top, rgb(51 141 245), rgb(51 141 245 / 50%));
  }

  .material-item:nth-child(3) {
    background: linear-gradient(to right top, rgb(44 174 219), rgb(44 174 219 / 50%));
  }

  .material-item:nth-child(4) {
    background: linear-gradient(to right top, rgb(254 183 94 / 100%), rgb(254 183 94 / 50%));
  }

  .material-item-new {
    position: absolute;
    left: 0;
    top: 0;
    width: 3vw;
    height: 1.5vw;
    line-height: 1.5vw;
    background: #f95f46;
    border-radius: 0.5vw 0;
    font-size: 1vw;
    color: #fff;
  }

  .material-item-title {
    font-size: 1.5vw;
    font-weight: bold;
    color: #fff;
  }

  .material-item-des {
    font-size: 1.3vw;
    line-height: 2vw;
    min-height: 24px;
    color: #fff;
  }

  .material-item-btn {
    min-width: 90px;
    line-height: 3vw;
    height: 3vw;
    color: red;
    background-color: white;
    border-radius: 1.5vw;
    text-align: center;
    font-size: 1.5vw;
  }
}
