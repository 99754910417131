#mn-modal-feedback {
  .el-dialog-body {
    .el-radio-group {
      display: flex;
      flex-direction: column;
    }

    .el-textarea-inner {
      border: 2px solid black;
      width: 100%;
    }
  }
}
