#mn-practice-di {
  .practice-panel {
    .body {
      .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: start;
        justify-items: center;

        img {
          width: 100%;
          height: 80%;
          object-fit: contain;
        }
      }
    }
  }

  .slideshow-container {
    position: relative;
  }

  .my-slides {
    display: none;
  }

  .active-slides {
    display: block;
    padding: 10px;
  }
}
