#mn-tutoring {
  width: 100%;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow-y: scroll;

  .tutoring-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: center;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;
    }

    h5 {
      border-bottom: 4px solid #a366cc;
      padding-bottom: 10px;
      width: fit-content;
    }

    .body {
      .tutor-list {
        .tutor {
          width: 100%;
          text-align: center;
          display: flex;
          flex-direction: row;
          margin: 15px;

          .tutor-img {
            width: 100%;
            border: 3px solid #ba66fa;

            img {
              width: 150px;
              height: 150px;
            }
          }

          .tutor-name {
            text-align: center;
            color: white;
            background-color: #ba66fa;
            width: 160px;
            height: 48px;
            padding: 10px;
          }
        }

        .tutor-info {
          border: 2px solid #ba66fa;
          width: 100%;
          height: 205px;
          margin-left: 15px;

          p {
            margin: 15px;
            text-align: left;
            font-size: 20px;
          }

          .more-btn {
            float: right;
            color: #ba66fa;
            margin-bottom: 15px;
            margin-right: 20px;
            font-size: 20px;
            width: 150px;
            height: 40px;
            border: 2px solid #ba66fa;

            &:hover {
              background-color: #ba66fa;
              color: white;
            }
          }
        }
      }
    }
  }
}
