.show-all {
  $size: 30px;

  position: fixed;
  top: 50%;
  right: 0;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  background-color: rgb(255 102 102);
  width: $size;
  height: $size * 2;
  border-bottom-left-radius: $size;
  border-top-left-radius: $size;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: calc($size / 1.5);
    height: calc($size / 1.5);
    color: white;
  }
}
