#mn-about {
  $bg: #f7f9fe;

  .background {
    width: 100%;
    height: 9.6rem;
    min-height: 700px;
    background-color: $bg;
    font-size: 1.2rem;

    .rudder-box {
      width: 12rem;
      min-width: 800px;
      margin: 0 auto;
      padding: 0.74rem 0;
      text-align: center;

      .box-middle {
        position: relative;
        width: 8rem;
        min-width: 500px;
        height: 8rem;
        min-height: 500px;
        border: 2px solid pink;
        border-radius: 50%;
        margin: 1.6rem auto;

        .rudder {
          width: 6rem;
          height: 6rem;
          position: absolute;
          border-radius: 50%;
          border: 6px solid pink;
          background-color: $bg;

          &:hover {
            border-color: var(--primary-color);
            cursor: pointer;
          }

          p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &__1 {
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &__3 {
            top: 50%;
            right: 0;
            transform: translate(50%, -50%);
          }

          &__5 {
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
          }

          &__7 {
            bottom: 50%;
            left: 0;
            transform: translate(-50%, 50%);
          }
        }

        .core {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          width: 5.65rem;
          min-width: 357px;
          height: 5.65rem;
          min-height: 357px;
          text-align: center;
          font-weight: bold;

          img {
            max-width: 100%;
            max-height: 100%;
          }

          .slogan {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50%;
            text-align: center;
          }

          .rudder {
            border-color: #5cb5ff;

            p {
              font-weight: normal;
            }

            &__2 {
              top: 0;
              right: 0;
              transform: translate(50%, -50%);
            }

            &__4 {
              bottom: 0;
              right: 0;
              transform: translate(50%, 50%);
            }

            &__6 {
              bottom: 0;
              left: 0;
              transform: translate(-50%, 50%);
            }

            &__8 {
              top: 0;
              left: 0;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
}
