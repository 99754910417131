.audio-indicator {
  $green: #6abb5c;
  $blue: #4fabe4;
  $white: #fff;

  border-style: solid;
  border-width: thin;
  border-color: #99aabf;
  background-color: #e7effa;
  width: 300px;
  margin: 0 auto;

  td {
    padding-left: 5px;
    padding-right: 5px;

    .btn {
      transition: all 0.4s ease-in-out;
      background: #fff;
      border: 1px solid #e5e5e5;
      border-radius: 0;
      outline: none;
      color: #b5b4b9;

      &:hover {
        background: $green;
        border-color: /* adjust-color($green, $lightness: 5);*/ $green;
        color: $white;
      }
    }
  }

  .processbar {
    height: 20px;

    &__prepare {
      background-color: lightgray;
    }

    &__practice {
      background-color: #69f;
    }
  }
}
