#mn-mock-test {
  width: 100%;
  box-shadow: var(--box-shadow);
  background-color: white;
  overflow-y: scroll;

  .title {
    border-radius: var(--border-radius);
    text-align: left;
    background-color: var(--primary-color);
    color: white;
    font-size: 20px;

    .go-back {
      color: white;
    }
  }

  .body {
    ul {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 2rem;

      li {
        border-radius: var(--border-radius);
        display: flex;
        flex-direction: row;

        .link-btn {
          border-radius: 10px;
          border: 3px solid white;
          margin-left: 800px;
          width: 130px;
          text-align: center;
        }
      }
    }
  }
}
