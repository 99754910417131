#mn-practice-fib {
  p {
    font-size: 18px;
    line-height: 40px;
    display: inline;
  }

  input {
    border: 1px solid black;
    width: 100px;
  }

  .check-btn {
    color: white;
    border: none;
    text-align: center;
    padding: 10px 22px;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 10px;
    background-color: #8155c2;
  }

  .answer {
    border: none;
    border-radius: var(--border-radius);
    background-color: aquamarine;
    text-align: center;
    width: fit-content;
    padding: 2px;
    height: 35px;
    margin-left: 5px;
  }

  .check-btn:hover {
    background-color: #4e2b82;
  }

  .blanks {
    display: inline-block;
    margin: 5px;
    width: 115px;
    height: 30px;
    border: 1px solid black;
  }

  #result {
    font-size: 20px;
  }
}
