#mn-testimony {
  img {
    width: 100%;
  }

  .swiper-button-prev {
    left: 5vw;
  }

  .swiper-button-next {
    transform: scaleX(-1);
    right: 5vw;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 4.5vw;
    height: 4.5vw;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
    margin: auto;
    pointer-events: auto;
    cursor: pointer;
    background-image: url("../../../../public/assets/images/arrow.avif");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    &::after {
      display: none;
    }

    &:hover {
      background-image: url("../../../../public/assets/images/arrow_hover.avif");
    }
  }
}
