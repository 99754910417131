#mn-question-all-comments {
  background-color: white;

  .allcomments {
    border-radius: var(--border-radius);
    background-color: white;

    .allcomments-list {
      background-color: white;
      display: flex;
      flex-direction: row;
      height: 100%;
      gap: 1rem;

      img {
        width: 50px;
        height: 50px;
      }

      .datetime {
        color: lightgray;
        font-size: small;
        display: inline;
      }

      .list-tab {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        width: 100%;
      }

      .no-comment {
        display: none;
      }

      .comments {
        display: inline;
      }

      .replies {
        background-color: gray;
        margin-left: 30px;
      }

      .button {
        color: #9049bf;
        background-color: white;
        border: none;
        padding: 2px;
        text-align: center;
      }

      .button:hover {
        color: #4e2b82;
      }
    }
  }
}
