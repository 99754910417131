#mn-platform {
  background: #f5f6ff;

  .practice-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
  }

  .practice-item {
    background-color: #fff;
    box-shadow: 0 8px 8px 0 rgb(89 91 178 / 4%);
    border-radius: 8px;
    padding: 20px;
    width: 48%;
    position: relative;
    margin-bottom: 20px;
    transition: all 0.3s;
  }

  .practice-item:hover {
    box-shadow: 0 12px 12px 0 rgb(89 91 178 / 10%);
    transform: translateY(-5px);
  }

  svg {
    padding: 0.7vw;
    border-radius: 100%;
    color: white;
    min-width: 50px;
    min-height: 50px;

    path {
      color: white;
    }
  }

  /* --- 练习平台 --- start */

  .item-head {
    margin-left: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
  }

  .practice-item p {
    letter-spacing: 0;
    color: #333;
    padding: 0 5px;
    margin-top: 15px;
  }

  .practice-item button {
    font-size: 18px;
    letter-spacing: 0;
    width: 100px;
    height: 32px;
    line-height: 32px;
    border-radius: 16px;
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .practice-item button img {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }

  .practice-item button .static-icon {
    display: inline-block;
  }

  .practice-item button .hover-icon {
    display: none;
  }

  .practice-item:hover button .static-icon {
    display: none;
  }

  .practice-item:hover button .hover-icon {
    display: inline-block;
  }

  .practice-item:nth-child(1) button {
    background-color: rgb(89 91 178 / 20%);
    color: #595bb2;
  }

  .practice-item:nth-child(2) button {
    background-color: rgb(26 123 249 / 20%);
    color: #1a7bf9;
  }

  .practice-item:nth-child(3) button {
    background-color: rgb(11 161 215 / 20%);
    color: #0ba1d7;
  }

  .practice-item:nth-child(4) button {
    background-color: rgb(255 172 76 / 20%);
    color: #ffac4c;
  }

  .practice-item:nth-child(1):hover button {
    background-color: rgb(89 91 178 / 100%);
    color: #fff;
  }

  .practice-item:nth-child(2):hover button {
    background-color: rgb(26 123 249 / 100%);
    color: #fff;
  }

  .practice-item:nth-child(3):hover button {
    background-color: rgb(11 161 215 / 100%);
    color: #fff;
  }

  .practice-item:nth-child(4):hover button {
    background-color: rgb(255 172 76 / 100%);
    color: #fff;
  }
}

@media only screen and (max-width: 960px) {
  #mn-platform {
    .practice-item {
      width: 100vw;

      .item-head {
        line-height: 50px;
        font-size: 20px;
      }
    }
  }
}
