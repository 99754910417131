#mn-my-collections {
  width: 100%;
  box-shadow: var(--box-shadow);

  .my-collections-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: center;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;
    }

    .body {
      .collect-options {
        border: 1px solid lightgray;
        background-color: white;
        display: flex;
        flex-direction: column;
        border-radius: var(--border-radius);
        margin: 10px;

        &:hover {
          box-shadow: var(--box-shadow);
        }

        p {
          margin-top: 10px;
        }

        .redirect-btn {
          color: gray;
          margin-left: 220px;
          padding-bottom: 10px;

          &:hover {
            color: #ae24ff;
          }
        }
      }
    }
  }
}
