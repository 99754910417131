/* --- header --- start */

.user-menu {
  position: absolute;
  bottom: -130px;
  left: 20%;
  z-index: 101;
  background-color: #fff;
  padding: 15px 15px 5px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 17.5%);
  border-radius: 5px;
  display: none;
}

.user-menu li {
  white-space: nowrap;
  line-height: 36px;
  height: 36px;
  font-size: 14px;
  letter-spacing: 1px;
  color: #333;
}

.user-menu li a {
  color: #7d5fe6;
}

.lxaap-menu-nav li {
  margin-bottom: 10px;
  line-height: normal;
}

header {
  background-color: #fff;
  box-shadow: 0 12px 12px 0 rgb(89 91 178 / 10%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  .header-download-m {
    display: none;
  }

  .header-space {
    height: 80px;
    line-height: 80px;
    font-size: 1.3vw;

    .logo {
      display: inline-block;
      vertical-align: middle;

      img {
        display: block;
        width: 205px;
      }
    }

    .header-nav {
      display: inline-flex;
      justify-content: space-between;
      margin-left: 10px;

      li {
        text-align: center;
        line-height: 80px;
        width: 6.25vw;

        a {
          letter-spacing: 0;
          color: #333;
          display: block;
          position: relative;
        }

        li:hover > a {
          color: var(--primary-color);
        }
      }

      li:hover > a::after {
        content: "";
        display: block;
        margin: auto;
        width: 25px;
        height: 4px;
        background-color: var(--primary-color);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .practice-list {
      background-color: #fff;
      border-radius: 5px;
      position: fixed;
      top: 93px;
      left: 0;
      width: 100%;
      z-index: 101;
      box-shadow: -1px -2px 12px 0 rgb(89 91 178 / 30%);
      display: flex;
      align-items: flex-start;
      justify-content: center;
      transform: scale(1);
      pointer-events: none;
      visibility: hidden;

      .practice-list-item {
        float: left;
        border-left: 1px solid #e5e5e5;
        display: block;
        margin-top: 1vw;
        padding: 0 0 0 5px;

        .item-head {
          line-height: 3vw;
          text-align: left;
          margin-left: 0.8vw;
          margin-bottom: 1vw;
          display: flex;
          align-items: center;

          span {
            font-size: 1.2vw;
            margin-left: 1.2vw;
          }
        }

        .item-content {
          line-height: 2vw;
          border: 1px solid rgb(255 255 255 / 0%);
          border-radius: 5px;
          text-align: left;
          cursor: pointer;
          padding: 0 5px;

          span {
            font-size: 1vw;
          }

          strong {
            font-size: 1vw;
            color: red;
          }

          strong::before {
            content: " / ";
          }
        }

        .item-content:hover {
          border: solid 1px #470fa8;
          font-weight: bold;
        }
      }

      .practice-list-item:nth-child(1) {
        width: 18%;
      }

      .practice-list-item:nth-child(2) {
        width: 18%;
      }

      .practice-list-item:nth-child(3) {
        width: 18%;
      }

      .practice-list-item:nth-child(4) {
        width: 20%;
      }

      .practice-list-item:nth-child(5) {
        width: 16%;
      }
    }

    .practice-hover:hover .practice-list {
      pointer-events: auto;
      visibility: visible;
      transform: scale(1.1);
      transition: all 0.3s;
    }

    .practice-list::before {
      position: absolute;
      z-index: -5;
      bottom: 100%;
      left: 350px;
      display: block;
      width: 16px;
      height: 16px;
      content: "";
      transform: rotate(-45deg) translateY(1rem);
      border-radius: 0.2rem;
      background: #fff;
      box-shadow: none;
    }

    .menu-nav-m {
      display: none;
    }

    .header-title-m {
      display: none;
    }

    .header-menu-m {
      display: none;
    }

    .login-handle,
    .logout-handle {
      letter-spacing: 0;
      color: #fff;
      width: 10vw;
      height: 40px;
      line-height: normal;
      background-color: #595bb2;
      border-radius: 20px;
      cursor: pointer;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 2rem;

      p {
        margin-top: 5px;
      }

      &:hover {
        background-color: #470fa8;
      }

      .avatar {
        width: 26px;
        height: 26px;
        margin-top: 5px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
    }

    .lxapp {
      width: 8vw;
      height: 100%;
      letter-spacing: 0;
      color: #595bb2;
      cursor: pointer;
    }

    .header-lan {
      margin-bottom: 0;
    }
  }
}

#head-user {
  width: 120px;
  height: 40px;
  background-color: #595bb2;
  border-radius: 20px;
  line-height: 40px;
  padding: 0 5px;
  margin-top: 20px;
  display: none;
  cursor: pointer;
  position: relative;
}

#head-user.active {
  display: block;
}

#head-user .avatar {
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  top: -1px;
}

#head-user .user-name {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0;
  color: #fff;
  white-space: nowrap;
  margin-left: 3px;
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#head-user:hover .user-menu {
  display: block;
}

.user-menu::before {
  position: absolute;
  z-index: -5;
  bottom: 100%;
  left: 22px;
  display: block;
  width: 16px;
  height: 16px;
  content: "";
  transform: rotate(-45deg) translateY(1rem);
  border-radius: 0.2rem;
  background: #fff;
  box-shadow: none;
}

.more-service-handle {
  font-size: 18px;
  letter-spacing: 0;
  color: #595bb2;
  width: 120px;
  height: 40px;
  line-height: normal;
  background-color: #e6e6f7;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 20px;
  position: relative;
}

.lxaap-menu {
  position: absolute;
  width: 820px;
  height: 487px;
  background: url("../../../../public/assets/images//block_appNew.avif") no-repeat;
  top: 70px;
  right: 45px;
  z-index: 100;
  display: none;
}

.lxaap-menu-nav {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 95px;
  left: 40px;
}

.lxaap-menu-nav li h3 {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0;
  color: #4657d8;
  margin-bottom: 5px;
  text-align: left;
}

.lxaap-menu-nav li p {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0;
  color: #333;
  text-align: left;
}

/* --- header --- end */

@media only screen and (max-width: 1230px) and (min-width: 960px) {
  header {
    .header-spface {
      .logo {
        img {
          width: 160px;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  /* --- header --- start */
  header {
    background-color: var(--primary-color) !important;
    position: static;

    .header-download-m {
      display: block;
    }

    .header-download-m img {
      display: block;
      width: 100%;
    }

    .header-space {
      height: auto;
      line-height: normal;
      position: relative;

      .logo {
        display: none;
      }

      .header-nav {
        display: none;
      }

      .lxapp {
        display: none;
      }

      .login-handle,
      .logout-handle {
        font-size: 0;
        margin-top: 10px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../../../../public/assets/images/nav_icon_Mlogin.avif");
        margin-right: 15px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2rem;

        p {
          margin-top: 5px;
        }

        &:hover {
          background-color: #470fa8;
        }

        .avatar {
          width: 26px;
          height: 26px;
          margin-top: 5px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }
      }

      .header-title-m {
        display: block;
        height: 48px;
        line-height: 48px;
        font-weight: bold;

        a {
          color: white;
        }
      }

      .header-lan {
        display: block;
        height: 48px;
        line-height: 48px;
        font-size: 20px;
        color: white;

        button {
          color: white;

          &:hover {
            color: black;
          }
        }
      }

      .header-menu-m {
        display: block;
        width: 28px;
        height: 28%;
        margin-top: 10px;
        margin-right: 15px;
        color: white;

        &:hover {
          color: black;
        }
      }

      .menu-nav-m {
        position: absolute;
        top: 48px;
        width: 100%;
        left: 0;
        right: 0;
        background-color: var(--primary-color);
        z-index: 100;
        padding: 10px 0;

        li {
          line-height: 36px;
          font-size: 12px;

          a {
            color: #fff;
            display: block;
            padding: 0 20px;

            &:hover {
              background-color: white;
              color: black;
            }
          }

          &.active {
            a {
              background-color: white;
              color: black;
            }
          }
        }
      }
    }
  }
}
