#mn-analysis {
  width: 100%;
  box-shadow: var(--box-shadow);

  .analysis-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: center;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;
    }

    .header {
      background-color: #dac7f2;
      text-align: center;
      border-radius: var(--border-radius);
      width: 100%;

      img {
        border-radius: 50%;
        width: 180px;
        height: 200px;
        padding-bottom: 10px;
        padding-top: 10px;
      }

      p {
        color: white;
        font-size: 18px;
        padding-bottom: 10px;
      }
    }

    .body {
      background-color: var(--shallow-bg-color);
      border-radius: var(--border-radius);
      height: 100%;

      h5 {
        padding-top: 10px;
        margin-left: 10px;
      }

      .input-date {
        margin-left: 10px;
        display: inline;
        padding-bottom: 10px;
      }

      .score-list {
        display: flex;
        flex-direction: row;

        .icon {
          margin-left: 10px;
          color: #ae24ff;
          margin-right: 10px;
          font-size: 25px;
        }

        p {
          padding-right: 10px;
          color: black;
          margin-top: 10px;
          font-size: 20px;
        }
      }

      .add-previous-data {
        button {
          background-color: #ae24ff;
          color: white;
          font-size: 20px;
          text-align: center;
          border-radius: 30px;
          width: 200px;
          margin-top: 10px;
          height: 50px;

          &:hover {
            background-color: #8f35c4;
          }
        }
      }
    }
  }
}
