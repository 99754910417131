#mn-stream-banner {
  position: relative;

  .bg {
    display: block;
    width: 100%;
    height: 552px;
    animation: fadeIn 0.3s forwards;
    background-image: url("../../../../public/assets/images/bg.avif");
    background-size: cover;
    cursor: pointer;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
  }

  .header-placehoder {
    height: 80px;
    visibility: hidden;
  }

  .slider-wrapper {
    width: 960px;
    height: 441px;
    border-radius: var(--border-radius);
    background-color: rgb(0 0 0 / 80%);
    overflow: hidden;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    .slider-tabs {
      box-sizing: content-box;

      .slider-tabs-list {
        overflow-y: scroll;
        height: 441px;

        .playlist-item {
          list-style: none;
          padding: 0;
          margin: 0;
          cursor: pointer;
          border: 2px solid purple;
        }
      }

      img {
        width: 100%;
      }
    }
  }
}
