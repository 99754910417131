#mn-practice-footer {
  background-color: lightgray;
  font-size: 16px;
  color: var(--primary-color);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    cursor: pointer;

    &.disabled {
      color: gray;
      cursor: not-allowed;
    }
  }

  .right,
  .left {
    display: inherit;
    gap: 1.5vw;

    .bg {
      background-color: white;
      padding: 5px 10px;
      border-radius: 20px;
      border: 2px solid lightgray;
    }

    .bg:first-child {
      background-color: var(--primary-color);
      color: white;
    }

    .bg:hover {
      border: 2px solid var(--primary-color);
    }
  }
}
