#mn-practice-header {
  width: 100%;
  background-color: var(--primary-color);
  color: white;
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--border-radius);

  svg {
    color: white;
    width: 20px;
    height: 20px;
  }

  .left {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 2vw;

    & > div {
      cursor: pointer;
      border-bottom: 2px solid var(--primary-color);
    }

    & > div:not(:first-child):hover {
      border-bottom: 2px solid #fff;
    }

    & > div:not(:first-child).active {
      border-bottom: 2px solid #fff;
    }

    .type {
      display: flex;
      align-items: center;
    }
  }

  span {
    margin-left: 15px;
  }
}

#mn-practice-category {
  background-color: lightgray;
  width: 100%;
  position: absolute;
  height: 50px;
  top: 10;
  left: 10;

  .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2vw;
    margin-left: 10px;

    .category {
      border-radius: var(--border-radius);
      text-align: center;
      border: none;
      background-color: #cfb4fa;
      width: fit-content 100px;
      padding: 5px;
      height: 30px;
      margin-top: 10px;
      font-size: 14px;
      color: #361675;
    }
  }
}

@media only screen and (max-width: 960px) {
  #mn-practice-header {
    font-size: 2vw;
  }
}
