#mn-short-courses {
  width: 100%;
  background-color: white;
  box-shadow: var(--box-shadow);
  overflow-y: scroll;

  .short-courses-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: center;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;
    }

    .courses {
      // background-color: var(--shallow-bg-color);
      border-radius: var(--border-radius);

      .courses-list {
        display: flex;
        justify-content: space-between;
        gap: 1 rem;
        flex-direction: row;

        button:hover {
          color: #ae24ff;
        }

        .btn-active {
          color: #ae24ff;
          border-bottom: 3px solid #ae24ff;
          padding-bottom: 10px;
        }

        .btn-unactive {
          color: black;
          border: none;
        }
      }

      .classes-list {
        gap: 2rem;
        background-color: var(--shallow-bg-color);
        border-radius: var(--border-radius);
        height: 100%;
        width: 100%;
        padding: 20px;

        .classes-info {
          padding: 10px;
          background-color: white;
          border-radius: var(--border-radius);
          text-align: center;
          margin: 10px;

          &:hover {
            box-shadow: var(--box-shadow);
          }

          .cover-img {
            align-content: center;
            padding: 10px;

            img {
              width: 250px;
              height: 130px;
            }
          }
        }

        .classes-unactive {
          display: none;
        }
      }
    }
  }
}
