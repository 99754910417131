#mn-model-test {
  width: 100%;
  box-shadow: var(--box-shadow);
  background-color: white;
  overflow-y: scroll;

  .title {
    border-radius: var(--border-radius);
    text-align: left;
    background-color: var(--primary-color);
    color: white;
    font-size: 20px;

    .go-back {
      color: white;
    }
  }

  .body {
    table {
      width: 100%;

      tr {
        border-bottom: 1px solid lightgray;

        th {
          color: darkgray;
        }

        td {
          color: black;
        }

        .link-btn {
          border-radius: 10px;
          border: 3px solid #9842f5;
          background-color: white;
          color: #9842f5;
          text-align: center;
          width: 150px;
          margin: 10px;
        }

        .link-btn:hover {
          color: #661db5;
          border-color: #661db5;
        }
      }
    }
  }
}
