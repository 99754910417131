#mn-make-plan {
  width: 100%;
  box-shadow: var(--box-shadow);
  background-color: var(--shallow-bg-color);
  overflow-y: scroll;

  .plan-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: left;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;

      .go-back {
        color: white;
      }
    }

    .body {
      .plan-title {
        display: flex;
        flex-direction: row;
        font-size: 20px;
        margin: 20px;
        gap: 2rem;

        .btn-unactive {
          text-align: center;
          border: 2px solid #ae24ff;
          border-radius: 30px;
          width: 100px;

          &:hover {
            background-color: #ae24ff;
            color: white;
          }
        }

        .btn-title {
          text-align: center;
          border-radius: 30px;
          width: 100px;
          background-color: #ae24ff;
          color: white;
        }

        .save-btn {
          margin-left: 450px;
          font-size: 20px;
          border: 2px solid black;
          border-radius: 30px;
          width: 150px;
          text-align: center;

          &:hover {
            background-color: lightgray;
          }
        }
      }

      .plan-option {
        table {
          width: 100%;
          text-align: left;
        }

        .table-header {
          display: flex;
          gap: 15rem;
          margin: 10px;
          font-size: 20px;
          border-bottom: 2px solid gray;
          padding-bottom: 10px;
        }

        .list-unactive {
          display: none;
        }

        .list-active {
          display: flex;
          flex-direction: column;

          tr {
            margin: 10px;
            font-size: 20px;
            border-bottom: 2px solid gray;
            padding-bottom: 10px;
            display: flex;
            gap: 8rem;
          }

          .type {
            width: 20%;
          }

          .quantity {
            border: 2px solid lightgray;
            width: 100px;
            text-align: center;
            margin-right: 100px;
          }

          .options-list {
            display: flex;
            width: 0;
            padding: 0;

            .options {
              display: inline;
            }
          }
        }
      }
    }
  }
}
