#mn-modal-memory {
  .body {
    text-align: center;
    padding: 30px;

    .date-input {
      border-radius: var(--border-radius);
      border: 3px solid lightgray;
      color: gray;
      width: 250px;
      height: 50px;
      font-size: 20px;
    }
  }

  .footer {
    text-align: center;
  }
}
