#mn-modal-feedback {
  width: 100%;

  h5 {
    padding: 10px;
  }

  input {
    border: 2px solid black;
  }

  textarea {
    border: 2px solid black;
    width: 100%;
  }

  .el-form-item {
    padding: 10px;
  }
}
