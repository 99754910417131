#mn-practice-hiw {
  .slideshow-container {
    position: relative;
  }

  .my-slides {
    display: none;
  }

  .active-slides {
    display: block;
    padding: 10px;
  }

  .answer {
    border: none;
    border-radius: var(--border-radius);
    background-color: aquamarine;
    text-align: center;
    width: fit-content;
    padding: 2px;
    height: 35px;
    margin-left: 5px;
    display: inline-block;
  }

  .non-active {
    display: none;
    background-color: white;
  }

  .text {
    display: inline;
  }

  .check-btn {
    color: white;
    border: none;
    text-align: center;
    padding: 10px 22px;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 10px;
    background-color: #8155c2;
  }

  .check-btn:hover {
    background-color: #4e2b82;
  }

  #result {
    font-size: 20px;
  }
}
