#mn-listen-collections {
  width: 100%;
  box-shadow: var(--box-shadow);

  .collections-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: left;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;
    }

    .go-back {
      color: white;
    }

    .body {
      .questions-collection {
        display: flex;
        flex-direction: row;
        margin: 15px;

        .questions-option {
          .question-type {
            width: 100px;
            margin-right: 10px;
            border-radius: 15px;
            border: 2px solid #ae24ff;
            color: #ae24ff;

            &:hover {
              background-color: #ae24ff;
              color: white;
            }
          }
        }
      }

      .empty-space {
        text-align: center;
        margin-top: 50px;

        img {
          width: 350px;
          height: 350px;
          padding-bottom: 10px;
        }
      }
    }
  }
}
