#mn-practice-rmcs {
  .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    justify-content: start;

    .right {
      .top {
        .btn.btn-primary {
          width: 25%;
        }
      }
    }
  }

  * {
    box-sizing: border-box;
  }

  .column {
    float: left;
    width: 50%;
    padding: 10px;
  }

  .row::after {
    content: "";
    display: table;
    clear: both;
  }

  .options {
    display: inline;
    padding-left: 5px;
  }

  .instruction {
    font-style: italic;
  }

  .slideshow-container {
    position: relative;
  }

  .my-slides {
    display: none;
  }

  .active-slides {
    display: block;
    padding: 10px;
  }

  .check-btn {
    color: white;
    border: none;
    text-align: center;
    padding: 10px 22px;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 10px;
    background-color: #8155c2;
  }

  .check-btn:hover {
    background-color: #4e2b82;
  }

  #result {
    font-size: 20px;
  }
}
