#mn-write-box {
  textarea {
    width: 100%;
    font-size: 1.1em;
    border: 2px solid #636161;
    background-color: white;
    color: black;
    overflow-y: scroll;
    resize: none;
  }

  .btns {
    display: flex;
    justify-content: space-between;

    button {
      border-radius: var(--border-radius);
      border: 2px solid gray;
      color: gray;

      &:hover {
        color: #373847;
        background-color: #9da5b1;
        border-color: #9da5b1;
      }
    }
  }
}
