.audio-player {
  width: 300px;
  border: 2px solid #aaa;
  margin: auto;

  audio {
    display: none;
    background-color: red;
  }

  td {
    padding-left: 5px;
    padding-right: 5px;

    table {
      td {
        padding: 0;

        input {
          width: 100%;
        }
      }
    }
  }

  .processbar {
    height: 20px;

    &__prepare {
      background-color: lightgray;
    }

    &__practice {
      background-color: #69f;
    }
  }
}
