#mn-practice-ra {
  width: 100%;

  .slideshow-container {
    position: relative;
  }

  .my-slides {
    display: none;
  }

  .active-slides {
    display: block;
    padding: 10px;
  }

  .content {
    border: 1px dashed black;
    text-align: left;
    padding: 10px 22px;
    margin-top: 20px;
    margin-left: 10px;
  }
}
