#mn-pte-research {
  width: 100%;
  box-shadow: var(--box-shadow);
  overflow-y: scroll;

  .pte-research-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: center;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;
    }

    .slide-container {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      width: 100%;
      font-size: 15px;

      button {
        &:hover {
          color: #ba66fa;
        }
      }

      .my-slides {
        text-align: center;
        display: block;
        margin-left: 10px;

        button {
          color: #ba66fa;
          border-bottom: 3px solid #ba66fa;
          padding-bottom: 10px;
        }
      }

      .title-unactive {
        text-align: center;
        display: block;
        margin-left: 10px;
        color: black;
      }
    }

    .classes-list {
      gap: 2rem;
      background-color: var(--shallow-bg-color);
      border-radius: var(--border-radius);
      height: 100%;
      width: 100%;
      padding: 20px;

      .classes-info {
        background-color: white;
        border-radius: var(--border-radius);
        text-align: center;
        width: 250px;
        height: 200px;
        margin: 10px;

        img {
          width: 250px;
          height: 130px;
          padding-bottom: 10px;
        }

        &:hover {
          box-shadow: var(--box-shadow);
        }
      }

      .classes-unactive {
        display: none;
      }
    }
  }
}
