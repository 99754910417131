#mn-compare {
  .main {
    box-shadow: 0 0 24px rgb(0 0 0 / 15%);
    margin: 0 auto;
    width: 1200px;
    font-size: 1.2vw;
    font-weight: bold;

    .price-table {
      width: 100%;
      border-collapse: collapse;
      border: 0 none;
      font-weight: 400;

      tr:not(:last-child) {
        border-bottom: 1px solid rgb(0 0 0 / 3%);
      }

      tr td {
        border-left: 1px solid rgb(0 0 0 / 5%);
        padding: 0.5vw 1.2vw;
      }

      tr td:first-child {
        border-left: 0 none;
      }

      tr:nth-child(1) {
        font-size: 2vw;
      }

      tr td:not(:first-child) {
        text-align: center;
      }

      tr:not(:first-child):hover {
        background-color: #eee;
      }

      tr.table-head {
        background-color: var(--primary-color);
        color: hsl(0deg 0% 100%);
      }

      tr.table-head td {
        font-weight: 600;
        text-transform: uppercase;
      }

      /* Highlighted column */

      /* body */
      tr td:nth-child(1) {
        background-color: rgb(216 214 227 / 15%);
        padding: 0.5vw 1.8vw;
        width: 18%;
      }

      tr td:nth-child(2) {
        background-color: var(--primary-color);
        padding: 0.5vw 1.8vw;
        color: white;
      }

      tr td:nth-child(3) {
        background-color: rgb(216 214 227 / 15%);
        padding: 0.5vw 1.8vw;
      }

      /* head */
      tr:nth-child(1) td:nth-child(1) {
        background-color: var(--shallow-color);
      }

      tr:nth-child(1) td:nth-child(3) {
        background-color: var(--shallow-color);
        color: var(--primary-color);
      }
    }
  }
}
