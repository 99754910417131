#mn-question-filter-dropdown {
  position: relative;

  .label {
    font-size: 0.875rem;
    color: #8c939d;
  }

  .header {
    text-decoration: none;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -4px;
      content: "";
      height: 2px;
      background-color: var(--primary-color);
    }

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  .body {
    display: none;
    position: absolute;
    z-index: 10;
    background-color: white;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
    width: 80%;

    &.open {
      display: block;
    }

    .item {
      cursor: pointer;

      &:hover {
        background-color: var(--clr-gray150);
      }

      &.selected {
        color: var(--primary-color);
        font-weight: bold;
      }
    }
  }
}
