#mn-question-my-practices {
  background-color: white;

  .mypractice {
    border-radius: var(--border-radius);
    background-color: white;

    .mypractice-list {
      background-color: white;
      display: flex;
      flex-direction: row;
      height: 100%;
      gap: 1rem;

      img {
        width: 50px;
        height: 50px;
      }

      audio {
        color: black;
        height: 50px;
      }

      .datetime {
        color: lightgray;
        font-size: small;
        display: inline;
      }

      .list-tab {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        width: 100%;
      }

      #button {
        background-color: #9049bf;
        width: 80px;
        height: 30px;
        border: none;
        padding: 2px;
        text-align: center;
      }

      #button:hover {
        background-color: #4e2b82;
      }
    }
  }
}
