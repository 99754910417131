#mn-modal-analysis {
  .body {
    text-align: left;
    padding: 30px;
    width: 100%;

    .date-input {
      // border-radius: var(--border-radius);
      border: 3px solid lightgray;
      color: gray;
      width: 250px;
      height: 50px;
      font-size: 20px;
    }

    .score-list {
      .score-info {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        text-align: center;

        .input-score {
          input {
            margin-left: 40px;
          }

          margin-top: 10px;
          margin-right: 10px;
          border-radius: var(--border-radius);
          background-color: lightgray;
          text-align: center;
          width: 80px;
          height: 80px;
        }
      }
    }
  }

  .footer {
    text-align: center;
  }
}
