#mn-practice-mcs {
  .slideshow-container {
    position: relative;
  }

  .my-slides {
    display: none;
  }

  .active-slides {
    display: block;
    padding: 10px;
  }

  .options {
    display: inline;
    padding-left: 5px;
  }

  .check-btn {
    color: white;
    border: none;
    text-align: center;
    padding: 10px 22px;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 10px;
    background-color: #8155c2;
  }

  .check-btn:hover {
    background-color: #4e2b82;
  }

  #result {
    font-size: 20px;
  }
}
