#mn-predict {
  width: 100%;
  box-shadow: var(--box-shadow);

  .practice-panel {
    .header {
      border-radius: var(--border-radius);
      height: 50px;
      text-align: left;
      background-color: var(--primary-color);

      span {
        color: white;
        font-size: 20px;
      }

      .header-right {
        display: inline;

        .input-with-select {
          float: right;
          display: flex;
          flex-direction: row;
          border-radius: var(--border-radius);
          border: 2px solid white;

          input {
            color: white;
            border-right: 2px solid white;
          }

          ::placeholder {
            color: white;
            font-size: smaller;
          }

          .search-btn {
            color: white;
            border-radius: 4px;
            background-color: #ba66fa;
          }

          .search-btn:hover {
            background-color: #661db5;
          }
        }
      }
    }

    .body {
      .question-option {
        button {
          margin-right: 10px;
          font-size: 20px;
        }

        .active {
          color: white;
          background-color: #9a44db;
          width: 120px;
          text-align: center;
          border: none;
          border-radius: var(--border-radius);
        }

        .unactive {
          color: black;
          background-color: white;
        }

        .unactive:hover {
          color: #9a44db;
        }
      }

      .info-list {
        width: 100%;
        background-color: var(--shallow-bg-color);
        height: 100%;
        border-radius: var(--border-radius);
        margin-top: 10px;

        .question-active {
          display: flex;
          flex-direction: row;
          gap: 1rem;
        }

        .question-unactive {
          display: none;
        }

        .info-active {
          b {
            color: #9a44db;
          }
        }

        .info-unactive {
          b {
            color: black;

            &:hover {
              color: #9a44db;
            }
          }
        }

        .type-active {
          display: flex;
          flex-direction: column;

          .rate-n-description {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            padding-top: 10px;

            .list-active {
              display: flex;
              flex-direction: column;
              margin-left: 10px;
              padding-right: 5px;
              border-right: 3px solid lightgrey;
              width: 30%;

              .rate-list {
                display: flex;
                flex-direction: row;

                .label {
                  display: inline-block;
                  align-content: center;
                }

                .container {
                  width: 100%;
                  background-color: lightgrey;
                  height: 10px;
                  border-radius: var(--border-radius);
                  display: inline;
                  margin-top: 10px;
                  margin-right: 5px;
                  padding-left: 0;

                  .rate {
                    height: 10px;
                    border-radius: var(--border-radius);
                  }
                }
              }
            }

            .description {
              display: flex;
              flex-direction: column;
            }
          }

          .sort-list {
            table {
              width: 100%;
              margin-left: 10px;

              tr {
                height: 50px;
                text-align: left;
                border-bottom: 2px solid lightgray;
              }

              tr:hover {
                background-color: #a6b5e0;
              }

              td:hover {
                font-style: italic;
              }
            }
          }
        }
      }
    }
  }
}
