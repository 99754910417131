#mn-practice-record {
  width: 100%;
  height: auto;
  box-shadow: var(--box-shadow);
  background-color: white;
  overflow-y: scroll;

  .practice-record-panel {
    .title {
      border-radius: var(--border-radius);
      text-align: center;
      background-color: var(--primary-color);
      color: white;
      font-size: 20px;
    }

    .records {
      ul {
        width: auto;
        display: inline;
      }

      .record-list {
        background-color: white;
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow);
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
        gap: 1vw;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background-color: rgb(53 220 216);
          border-radius: var(--border-radius);
          color: rgb(255 255 255);
          height: 50%;

          p {
            border: 1px solid rgb(255 255 255);
            padding: 3px 7px;
            border-radius: 4px;
          }
        }

        .body {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: auto;
          gap: 2vw;

          .title {
            color: rgb(51 51 51);
            background-color: white;
            font-size: 30px;
          }

          .des {
            color: gray;
            font-size: 20px;
          }
        }

        .footer {
          color: rgb(153 153 153);
          display: flex;
          justify-content: flex-end;

          svg {
            width: 1.5vw;
            height: 1.5vw;
          }
        }
      }
    }
  }
}
