#mn-practice-ro {
  * {
    box-sizing: border-box;
  }

  table,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    height: 20px;
  }

  table {
    width: 100%;
  }

  b {
    text-align: center;
  }

  .source {
    float: bottom;
    width: 550px;
    height: 95px;
  }

  .column {
    float: left;
    width: 50%;
    padding: 20px;
  }

  .target {
    height: 50%;
  }

  .row::after {
    content: "";
    display: table;
    clear: both;
  }

  .slideshow-container {
    position: relative;
  }

  .my-slides {
    display: none;
  }

  .active-slides {
    display: block;
    padding: 10px;
  }

  .check-btn {
    color: white;
    border: none;
    text-align: center;
    padding: 10px 22px;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 10px;
    background-color: #8155c2;
  }

  .check-btn:hover {
    background-color: #4e2b82;
  }

  #result {
    font-size: 20px;
  }

  #header {
    margin-left: 200px;
  }

  #div1,
  #div2,
  #div3,
  #div4,
  #div5 {
    float: bottom;
    width: 500px;
    height: 80px;
    border: 1px solid black;
    text-align: center;
  }
}
