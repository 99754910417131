#mn-admin-page {
  width: 100vw;
  height: 100vh;

  main {
    $top: 50px;

    position: absolute;
    width: 100%;
    top: $top;
    left: 0;
    height: calc(100vh - $top);
    display: flex;
    flex-direction: row;
    gap: 30px;
    background-color: var(--clr-gray100);
  }
}
