#mn-login-form {
  margin-bottom: 200px;

  .login-box {
    width: 33%;
    background-color: #fff;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    text-align: center;
    margin: auto;
    transform: translateY(120px);

    .header {
      height: 80px;
      display: flex;
      justify-content: space-around;
      border-bottom: 2px solid #dcdcdc;
      margin: 0 auto;

      .item {
        width: 77px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        font-size: 18px;
        color: #424242;
        cursor: pointer;

        &.active {
          color: var(--primary-color);
          border-bottom: 2px solid var(--primary-color);
        }

        &.hide {
          display: none;
        }
      }
    }

    .detail {
      height: calc(100% - 80px - 2px);
      display: flex;
      justify-content: center;

      div {
        width: 100%;
      }

      form {
        .row:first-child {
          .col-sm-4:first-child {
            .form-control {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              border-right: 0;
              background-color: var(--clr-gray150);
            }
          }

          .col-sm-8:last-child {
            .form-control {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
      }

      .enter {
        height: 52px;
        line-height: 52px;
        background-color: var(--primary-color);
        border-radius: 5px;
        cursor: pointer;
        color: #fff;
        text-align: center;

        &.disabled {
          cursor: no-drop;
          background-color: var(--primary-disable-color);
        }
      }

      .options {
        display: flex;
        justify-content: space-between;
        color: black;
        letter-spacing: 0.01rem;

        div {
          cursor: pointer;
        }

        .register {
          color: var(--primary-color);
        }
      }
    }

    .dash {
      width: 100%;
      color: gray;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .social-media-login {
      margin-left: 150px;
      margin-right: 150px;
      padding-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 960px) {
  #mn-login-form {
    .login-box {
      transform: translateY(40px);
    }
  }
}
