#mn-practice-rs {
  .audio-speaker {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .slideshow-container {
    position: relative;
  }

  .my-slides {
    display: none;
  }

  .active-slides {
    display: block;
    padding: 10px;
  }
}
